<template>
  <div class="connection-modal" ref="connectionModal">
    <a-modal
      v-model:open="configs.waitingOpen"
      :closable="false"
      :width="480"
      :getContainer="() => connectionModal"
      :destroyOnClose="true"
      :maskClosable="false"
      :footer="null"
    >
      <div class="content">
        <a-spin size="large" />
        <p>Please wait… this will just take a few seconds.</p>
      </div>
      <template #title>
        <div class="modal-title">Connecting your Amazon account</div>
      </template>
    </a-modal>
    <artmisads-modal
      v-model:open="configs.failedOpen"
      :width="600"
      :getContainer="() => connectionModal"
      :footer="null"
    >
      <div class="fail-content">
        <div v-if="configs.errorCode=='2010'">
          <div>This Amazon Seller Name is already associated with another Artemis Account. Please try again or contact the Admin of this Seller Account for further details.</div>
          <div>For additional support, feel free to email us at </div>
        </div>
        <div v-else>
          <div>An error occurred! Please try again or reach out to the Admin of this Seller Account for more information.</div>
          <div>For further assistance, feel free to email us at </div>
        </div>
        <div>
          <a class="email">Support@ArtemisAds.com</a>
        </div>
      </div>
      <template #title>
        <div class="modal-title">Connection failed</div>
      </template>
    </artmisads-modal>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
const { configs } = defineProps(["configs"]);
const connectionModal = ref();
// onMounted(() => {
//   console.log(configs);
// });
</script>
<style lang="less" scoped>
.connection-modal {
  .modal-title {
    text-align: center;
    font-size: 24px;
  }
  .content {
    padding-top: 48px;
    width: 100%;
    height: 240px;
    background: var(--color-white);
    // box-shadow: var(--section-box-shadow-2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
      margin-top: 16px;
      width: 239px;
      height: 40px;
      text-align: center;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: var(--dark-2);
    }
  }
  .fail-content{
    .email{
      margin-bottom: 24px;
      text-decoration: underline;
      color: var(--dark-2);
      font-weight: 500;
      &:hover{
        color: var(--dark-1);
      }
    }
  }
}
</style>
